import React from 'react'

const AboutPage = () => (
  <div>
    <h2>About</h2>
    <p>About Content</p>
  </div>
)

export default AboutPage
